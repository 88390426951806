@media (max-width: 1380px) {
      .project-name{
          font-size: 18px;
      }
    
      .desc {
          font-size: 14px;
      }
  }
  @media (max-width: 768px) {
    .project-name{
        font-size: 14px;
    }
    .desc {
        font-size: 12px;
    }
  }

  