
@media (max-width: 1380px) {
  .projects {
      font-size: 22px;
    }
}
@media (max-width: 768px) {
  .projects {
    font-size: 16px;
  }
}

.projects{
  margin-left: 120px;
  margin-bottom: 50px;
  padding-top: 120px;
  
}

