ul.timeline-3 {
    list-style-type: none;
    position: relative;
  }
  ul.timeline-3:before {
    content: " ";
    background: #d4d9df;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: 100%;
    z-index: 400;
  }
  ul.timeline-3 > li {
    padding-left: 20px;
  }
  ul.timeline-3 > li:before {
    content: " ";
    background: white;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid rgb(60, 110, 245, 0.866);
    left: 20px;
    width: 20px;
    height: 20px;
    z-index: 400;
  }

  .extra-space{
    margin-top: 50px;

  }

  @media (max-width: 1380px) {
    .experience {
        font-size: 22px;
      }
      .company-name{
          font-size: 18px;
      }
      .date{
        font-size: 14px;
      }
      .role {
          font-size: 14px;
      }
      .desc {
          font-size: 14px;
      }
  }
  @media (max-width: 768px) {

    .experience {
      font-size: 16px;
    }
    .company-name{
        font-size: 14px;
    }
    .date{
      font-size: 12px;
    }
    .role {
        font-size: 12px;
    }
    .desc {
        font-size: 12px;
    }
  }

  