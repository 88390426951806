.glow-on-hover:hover  {
    box-shadow: 0 0 20px 2px rgba(60, 110, 245, 0.866); 
    transition: box-shadow 0.5s ease-in-out;
    border-radius: 50px; 
  }
  
  .navigation-bar{ 
    position: fixed;
    border-style: solid;
    border-color: rgba(143, 147, 253, 0.2);
    border-width: 2px;
    justify-content: center
  }
  
  @media (min-width: 800px) {
    .navigation-bar{ 
      max-width: 50%;
    }
  }

  .navbar-light{
    background-color: #f5f8ff
  }
  
  .navbar-dark{
    background-color: #0c0c35
  } 