.main {
    width: 90%;
    padding: 10px 10px;
    margin: 0px auto;
    margin-top: 4rem;
  }
  .intro-main {
    width: 90%;
    padding: 10px 10px;
    margin: 0px auto;
    margin-top: 2rem;
  }
  
  .intro-main {
    width: 90%;
    padding: 10px 10px;
    margin: 0px auto;
  }
  
  .intro-main-main {
    display: flex;
  }
  
  .intro-main > * {
    flex: 1;
    margin-bottom: 10px;
    margin-top: 80px;
  }
  
  .intro-text {
    font-size: 24px;
    line-height: 1.1;
  }
  
  .intro-text-p {
    font-size: 18px;
    line-height: 40px;
    text-justify: auto
  }
  
  @keyframes wave {
    0% {
      transform: rotate(0deg);
    }
    10% {
      transform: rotate(-10deg);
    }
    20% {
      transform: rotate(12deg);
    }
    30% {
      transform: rotate(-10deg);
    }
    40% {
      transform: rotate(9deg);
    }
    50% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  
  /* Media Query */
  @media (max-width: 1380px) {
    .intro-text {
      font-size: 22px;
    }
    .intro-text-p {
      font-size: 18px;
    }

    .button-cv{
      font-size: 18px;
    }

    .button-get-in-touch{
      font-size: 18px;
    }
  }
  @media (max-width: 768px) {
    .button-intro-div {
      justify-content: space-around;
    }
    .intro-text {
      font-size: 16px;
      text-align: center;
    }
    .intro-text-p {
      font-size: 12px;
      line-height: normal;
      text-align: center;
    }
    .intro-main {
      display: block;
    }
    .button-cv{
      font-size: 16px;
    }

    .button-get-in-touch{
      font-size: 16px;
    }
  }

.btn-container{
  display: flex;
  justify-content: center;
  margin-bottom: 200px;
  align-items: center;
  justify-content: center;
}  


.glow-on-hover:hover  {
  box-shadow: 0 0 20px 2px rgba(60, 110, 245, 0.866); 
  transition: box-shadow 0.5s ease-in-out;
  border-radius: 50px; 
}
  