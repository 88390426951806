.dark-theme {
  color: #f5f8ff;
}

.light-theme {
  color: #272b69;
}

.icon-light-color{
  color: white
}

.icon-dark-color{
  color: #272b69
}